import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Navbar from '../components/navbar'

import uzoma from '../images/uzoma_studio.jpg'

const About = () => {
    return (
        <Layout>

            <SEO title="About A-KRA Studio" />

            <Navbar />

            <section className="section pt-0 about-section-mobile gradient about-gradient-bg">
                <div className="section section-mobile container content is-size-4">
                    <h2>About</h2>
                    <p>
                        A-Kra Studio is an art futurist laboratory. Guided by the Akan concept of soul (kra), 
                        we nurture the imagination through play, research, and experimentation. 
                    </p>
                    <p>
                        What world(s) are possible and how can our dreams lead us there?
                    </p>
                </div>

                <div className="section section-mobile pt-0 container content is-size-4">
                    <h2>Founder</h2>

                    <p>
                        Yaa Addae does, and cares about, a lot of things. Her personal website is currently in shambles 
                        so in the meantime, you can find her on Instagram <a href="https://www.instagram.com/yaatheplant/?hl=en" 
                        target="_blank" rel="noopener noreferrer">here</a>, and her portfolio <a 
                        href="https://yaaaddae.contently.com/" target="_blank" 
                        rel="noopener noreferrer">here</a>.
                    </p>
                </div>

                <div className="section section-mobile pt-0 container content is-size-4">
                    <h2>Past Lives</h2>
                    <div className="columns is-vcentered">
                        <div className="column is-two-thirds">
                            <iframe src="https://app.milanote.com/1KbprI1Z9JQteS?p=xqiwK2RjUqQ" 
                                width="100%" height="500" frameBorder="0" className="past-lives-milanote" title="A-KRA Past Lives on Milanote"></iframe>
                        </div>

                        <ul className="column is-one-third is-size-5 has-text-justified past-lives">
                            <li>ART FUTURES DREAM TANK (Upcoming)</li>
                            <li>A-KRA X CHURCH OF BLACK FEMINIST THOUGHT</li>
                            <li>A-KRA X NATACHI MEZ</li>
                            <li>A-KRA X FREE THE YOUTH, NIKE CAMPAIGN</li>
                            <li>A-KRA X TONI MORRISON ‘THE PIECES I AM’</li>
                            <li>A-KRA HOT GIRL SUMMER FUNFAIR</li>
                        </ul>
                    </div>
                </div>
                
                <div className="section section-mobile pt-0 container content">
                    <h2>Info</h2>

                    <div className="columns">

                        <div className="column">
                            <div className="pb-5">
                                <p className="is-size-4">Socials</p>
                                <p className="is-size-5"><a href="https://www.instagram.com/akrastudio/" target="_blank" rel="noopener noreferrer">A-KRA Studio on Insta</a></p>
                                <p className="is-size-5"><a href="https://www.instagram.com/decolonizetheartworld" target="_blank" rel="noopener noreferrer">Decolonize the Art World on Insta</a></p>
                                <p className="is-size-5"><a href="https://decolonizetheart.world" target="_blank" rel="noopener noreferrer">Decolonize the Art World on Are.na</a></p>
                            </div>

                            <div>
                                <p className="is-size-4">Email</p>
                                <p className="is-size-5">
                                    <a href="mailto:yaa@akra.studio" target="_blank" rel="noopener noreferrer">yaa@akra.studio</a>
                                </p>
                            </div>
                        </div>

                        <div className="column">

                            <p className="is-size-4 website-by-mobile">Website by</p>
                            <div className="is-size-5">
                                <a href="https://uzomaorji.com/tech" target="_blank" rel="noopener noreferrer" className="columns is-vcentered">
                                    <img src={uzoma} alt="uzoma studio logo" 
                                        className="mb-0 uzoma-studio-logo"
                                    />
                                    <span className="column is-6">uzoma://studio</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default About
