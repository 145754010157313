import React, { useState } from 'react'

import { Link } from 'gatsby'

import rocket from '../images/rocket.gif'

const Navbar = () => {

    const [ showMenu, setShowMenu ] = useState(false)

    const navbarBgDisplayClass = showMenu ? 'navbar-show-menu' : ''

    return (
        <nav className={`navbar section py-4 ${navbarBgDisplayClass}`} role="navigation" aria-label="main-navigation">
            <div className="navbar-brand">
                <div id="teleport" className="navbar-item" 
                    onClick={() => setShowMenu(!showMenu) }
                    onKeyPress={() => setShowMenu(!showMenu) }
                    role="button"
                    tabIndex={0}
                >
                    <img src={rocket} alt="spinning rocket gif" />
                </div>
            </div>

            {
                showMenu &&
                    <div className="navbar-menu is-active">
                        <Link to='/'>
                            <div id="navbar-home" className="navbar-item">
                                <span role="img" aria-label="sun emoji" className="has-text-centered is-size-2" 
                                style={{width: '100%'}}>
                                    ☄️
                                </span>
                            </div>
                        </Link>
                        <Link to='/about'>
                            <div id="navbar-about" className="navbar-item">
                                <p>About</p>
                            </div>
                        </Link>
                        <Link to='/decolonize-the-art-world'>
                            <div id="navbar-decolonize" className="navbar-item">
                                <p>Decolonize the Art World</p>
                            </div>
                        </Link>
                        <Link to='/imaginarium'>
                            <div id="navbar-imaginarium" className="navbar-item">
                                <p>Imaginarium</p>
                            </div>
                        </Link>
                        <Link to='/the-lab'>
                            <div id="navbar-the-lab" className="navbar-item">
                                <p>The Lab</p>
                            </div>
                        </Link>
                        <Link to='/portal'>
                            <div id="navbar-portal" className="navbar-item">
                                <p>Portal</p>
                            </div>
                        </Link>
                    </div>
            }
        </nav>
    )
}

export default Navbar
